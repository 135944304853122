import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {Link as GatsbyLink} from 'gatsby';
import {Link} from 'react-scroll';


export default function Footer(props) {
    return (
        <div className="footer-wrapper">

            <div className="footer-container">

                <div className="logo-wrapper footer-box">
                    <GatsbyLink aria-label="home page link" className="logo-link" to="/"/>
                    <div className="logo-container"/>
                </div>
                
                <div className="footer-box">
                    <p>CONTACT</p>
                    <ul>

                        <li>
                            <a className="contact-link" href="tel:+17037550958">
                                <span><FontAwesomeIcon className="fa-icon" icon={faPhone}/></span>
                                <span>
                                    <p>(703) 755-0958</p>
                                </span>
                            </a>
                        </li>

                        <li>
                            <a className="contact-link" activeClassName="contact-link" href="mailto:office@castlconstruction.com">
                                <span><FontAwesomeIcon className="fa-icon" icon={faEnvelope}/></span>
                                <span>
                                    <p>Office@castlconstruction.com</p>
                                </span>
                            </a>
                        </li>

                        <li>
                            <a className="contact-link" activeClassName="contact-link" href="https://maps.google.com/?q=Farifax, Virginia">
                                <span><FontAwesomeIcon className="fa-icon" icon={faMapMarkerAlt}/></span>
                                <span>
                                    <p>Fairfax, VA</p>
                                </span>
                            </a>
                        </li>

                    </ul>
                </div>

                <div className="footer-box">
                    <p>NAVIGATION</p>
                    {
                        (props.pageName != '/') ? 

                            <ul>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/">Home</GatsbyLink> </li>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/#AboutUs" smooth={true} duration={500}>About Us</GatsbyLink> </li>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/#Services" smooth={true}>Services</GatsbyLink> </li>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/gallery"> Project Gallery</GatsbyLink> </li>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/contact">Contact Us</GatsbyLink> </li>
                            </ul>
                        : 
                            <ul>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/">Home</GatsbyLink> </li>
                                <li> <Link className="nav-link" activeClass="nav-link" to="AboutUs" smooth={true} duration={500}>About Us</Link> </li>
                                <li> <Link className="nav-link" activeClass="nav-link" to="Services" smooth={true}>Services</Link> </li>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/gallery" > Project Gallery</GatsbyLink> </li>
                                <li> <GatsbyLink className="nav-link" activeClass="nav-link" to="/contact">Contact Us</GatsbyLink> </li>
                            </ul>
                    }
                </div>  

                <div className="footer-box">
                    <p>SERVICE AREAS</p>
                    <ul>
                        <li>Virginia</li>
                        <li>Maryland</li>
                        <li>Washington D.C.</li>
                    </ul>
                </div>

            </div>

            <div className="copy-right-container">
                <p>{new Date().getFullYear()} &copy; Castl Construction LLC | All Rights Reserved.</p>
            </div>
        </div>
    )
}
