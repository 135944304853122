import React, {useState} from 'react';

export default function Contact() {

    const encode = (data) =>{
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const [formState, setFormState] = useState({
        name: "",
        email: "", 
        phone: "",
    });

    function handleChange(e){
        e.preventDefault();
        setFormState({
            ...formState,
           [e.target.name] : e.target.value,
        })
    }

    function handleSubmit(e){
        fetch("/", {
            method: "POST",
            headers: {"Content-Type" : "application/x-www-form-urlencoded"},
            body: encode({"form-name" : "castl-contact-section-form", ...formState})
        })
            .then(() => alert("Message Sent!"))
            .catch(error => alert(error));

        setFormState({
            name: "",
            email: "",
            phone: "",
        });
        
        e.preventDefault();
    }

    return (
        <div id="ContactUs" className="contact-container">
            <p>are you ready to transform your home?</p>
            <h1>schedule a free estimate today!</h1>
            <form 
                name="castl-contact-section-form" 
                method="POST"
                data-netlify="true" 
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="form-name" value="castl-contact-section-form" />
                <label for="name">Full Name</label>
                <input onChange={handleChange} value={formState.name} type="text" name="name" id="name" placeholder=" Full Name *"></input>
                <label for="email">Your Email</label>
                <input onChange={handleChange} value={formState.email} type="email" name="email" id="email" placeholder=" Your Email *"></input>
                <label for="phone">Your Phone</label>
                <input onChange={handleChange} value={formState.phone} type="tel" name="phone" id="phone" placeholder=" Your Phone *"></input>
                <div type="submit" className="button-wrapper"><button>SEND</button></div>
            </form>
        </div>
    )
}
